<template>
	<div style="width: 100%">
		<StatisticsInfo :list="list" />
		<div class="content">
			<el-tabs v-model="activeName" type="card">
				<el-tab-pane label="床位签约" name="first">
					<transition>
						<SignTable v-if="activeName === 'first'" />
					</transition>
				</el-tab-pane>
				<el-tab-pane label="床位建设" name="second">
					<transition>
						<BuildTable v-if="activeName === 'second'" />
					</transition>
				</el-tab-pane>
				<el-tab-pane label="床位服务监管" name="third">
					<transition>
						<SuperviseTable v-if="activeName === 'third'" />
					</transition>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import StatisticsInfo from './components/statisticsInfo.vue'
import SignTable from './components/signTable.vue'
import BuildTable from './components/buildTable.vue'
import SuperviseTable from './components/superviseTable.vue'
import { getCount } from '@/api/bedManage'
export default {
	// 床位管理
	name: 'bedManage',
	components: {
		StatisticsInfo,
		SignTable,
		BuildTable,
		SuperviseTable,
	},
	data() {
		return {
			activeName: 'first',
			list: [
				{
					url: require('../../assets/imgs/total.png'),
					num: 0,
					title: '家庭养老床位总数',
					lineTip: [[{ name: '其中停发总数', bg: '#FFC100', num: '0' }]],
					otherStyle: {
						marginTop: '.229167rem' /* 44/192 */,
						justifyContent: 'flex-end',
					},
				},
				{
					url: require('../../assets/imgs/signed.png'),
					num: 0,
					title: '已签约总数',
					lineTip: [[{ name: '其中停发总数', bg: '#FFC100', num: '0' }]],
					otherStyle: {
						marginTop: '.229167rem' /* 44/192 */,
						justifyContent: 'flex-end',
					},
				},
				{
					url: require('../../assets/imgs/build.png'),
					num: 0,
					title: '床位建设总数',
					lineTip: [
						[
							{ name: '待建设', bg: '#FF5C4C', num: '0' },
							{ name: '建设完成', bg: '#D367D8', num: '0' },
						],
						[
							{ name: '建设中', bg: '#3FCF89', num: '0' },
							{ name: '验收中  ', bg: '#7BC9FE', num: '0' },
						],
					],
				},
				{
					url: require('../../assets/imgs/supervise.png'),
					num: 0,
					title: '床位服务监管总数',
					lineTip: [
						[
							{ name: '待服务', bg: '#FF5C4C', num: '0' },
							{ name: '服务中', bg: '#3FCF89', num: '0' },
						],
					],
					otherStyle: {
						marginTop: '.229167rem' /* 44/192 */,
					},
				},
			],
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			getCount()
				.then((res) => {
					this.list[0].num = res.data.allCount
					this.list[0].lineTip[0][0].num = res.data.allCountStopHair
					this.list[1].num = res.data.contract
					this.list[1].lineTip[0][0].num = res.data.contractStopHair
					this.list[2].num = res.data.bedBuilding
					this.list[2].lineTip[0][0].num = res.data.waitBuilding
					this.list[2].lineTip[0][1].num = res.data.accomplishBuilding
					this.list[2].lineTip[1][0].num = res.data.building
					this.list[2].lineTip[1][1].num = res.data.acceptanceCheck
					this.list[3].num = res.data.supervise
					this.list[3].lineTip[0][0].num = res.data.forService
					this.list[3].lineTip[0][1].num = res.data.inService
				})
				.catch(() => {
					this.list[0].num = 0
					this.list[1].num = 0
					this.list[2].num = 0
				})
		},
	},
}
</script>

<style lang="scss" scoped>
.content {
	background: #fff;
	margin-top: 20px;
}
</style>
