<template>
	<div class="buildTable">
		<SearchForm :showSign="true" :showOrgan="true" :showState="true" @searchSubmit="doSearch" />
		<el-table v-loading="loading" style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle">
			<template slot="empty">
				<IsEmpty />
			</template>
			<el-table-column type="index" label="序号" width="50" align="center" />
			<el-table-column prop="userName" label="老人姓名" align="center" width="100" />
			<el-table-column prop="phone" label="联系方式" align="center" width="120" />
			<el-table-column prop="street" label="所属组织" align="center" width="200">
				<template slot-scope="scope">
					<span>{{ scope.row.tenantName ? `${scope.row.tenantName}/${scope.row.organizationName}` : '--' }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="organName" label="服务机构" align="center" width="200" :show-overflow-tooltip="true" />
			<el-table-column prop="elderlyAbility" label="老人能力评估" align="center" width="140">
				<template slot-scope="scope">
					{{ getContent(ELDERLYABILITY, scope.row.elderlyAbility, 'label', 'text') }}
				</template>
			</el-table-column>
			<el-table-column prop="elderlyType" label="老人类型" align="center" width="100">
				<template slot-scope="scope">
					{{ getContent(ELDERLYTYPE, scope.row.elderlyType, 'label', 'text') }}
				</template>
			</el-table-column>
			<el-table-column prop="serviceSubsidy" label="服务补贴标准(%)" align="center" width="140" />
			<el-table-column prop="serviceMaxFee" label="服务最高标准(元)" align="center" width="140" />
			<el-table-column prop="bedStatus" label="签约状态" align="center" width="100">
				<template slot-scope="scope">
					{{ getContent(SIGNOPTIONS, scope.row.signStatus, 'label', 'text') }}
				</template>
			</el-table-column>
			<el-table-column prop="bedStatus" label="建设状态" align="center" width="100">
				<template slot-scope="scope">
					{{ getContent(BUILDALLSTATE, scope.row.status, 'label', 'text') }}
				</template>
			</el-table-column>
			<el-table-column prop="bedStatus" label="服务状态" align="center" width="100">
				<template slot-scope="scope">
					{{ getContent(SERVICEOPTIONS, scope.row.serviceStatus, 'label', 'text') }}
				</template>
			</el-table-column>
			<el-table-column prop="serviceAgreementImg" label="服务协议" align="center">
				<template slot-scope="scope">
					<el-button type="text" @click="downFile(scope.row.allFiles)">查看附件</el-button>
					<el-button type="text" style="margin-left: 0" @click="$zoomIn(scope.row.allFiles !== null ? scope.row.allFiles.imgAddress : [])">查看图片</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="address6" label="操作" width="150" align="center" fixed="right">
				<template slot-scope="scope">
					<el-button type="text" @click="seeDetail(scope.row)">查看详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="tableParams.pageNum"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="tableParams.pageSize"
			layout="total, sizes, prev, pager, next"
			:total="total"
		>
		</el-pagination>
		<!-- 床位详情弹窗 -->
		<BedDetail v-if="dialogVisible" :detailVisible.sync="dialogVisible" :bedInfo="rowData" />
		<!-- 查看图片弹窗 -->
		<ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
	</div>
</template>

<script>
import { getContent, downloadFile } from '@/utils/index'
import SearchForm from '../components/searchFrom.vue'
import BedDetail from '../components/bedDetail.vue'
import { signPage } from '@/api/bedManage'
import { BUILDALLSTATE, ELDERLYABILITY, ELDERLYTYPE, SIGNOPTIONS, SERVICEOPTIONS } from '@/data/dataDictionary'
export default {
	// 床位签约
	name: 'signTable',
	components: {
		SearchForm,
		BedDetail,
	},
	data() {
		return {
			BUILDALLSTATE,
			ELDERLYABILITY,
			ELDERLYTYPE,
			SIGNOPTIONS,
			SERVICEOPTIONS,
			loading: false,
			dialogVisible: false,
			rowData: {}, // 床位信息
			imageVisible: false,
			imagesList: [], // 图片list
			searchForm: {},
			tableData: [],
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 0,
		}
	},
	mounted() {
		this.getList()
	},
	methods: {
		getContent,
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.tableParams.pageNum = 1
			this.getList()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		doSearch(params) {
			this.searchForm = params
			this.tableParams.pageNum = 1
			this.getList()
		},
		getList() {
			this.loading = true
			signPage({ ...this.searchForm, ...this.tableParams })
				.then((res) => {
					this.tableData = res.data.items
					this.total = res.data.counts
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
		// 查看附件
		downFile(fileUrl) {
			if (fileUrl && fileUrl.fileAddress.value) {
				const href = fileUrl.fileAddress.value
				downloadFile(href)
			} else {
				this.$message.info('暂无附件')
			}
		},
		// 查看图片
		seeImage(imgs) {
			if (imgs && imgs.imgAddress.length > 0) {
				const imgsList = imgs.imgAddress.map((item) => {
					return { url: item.value }
				})
				this.imagesList = imgsList
				this.imageVisible = true
			} else {
				this.$message.info('暂无图片')
			}
		},
		// 查看详情
		seeDetail(row) {
			this.rowData = row
			this.dialogVisible = true
		},
	},
}
</script>

<style lang="scss" scoped>
.buildTable {
	padding: 0.15625rem /* 30/192 */;
}
</style>
