import { render, staticRenderFns } from "./bedManage.vue?vue&type=template&id=40e6df66&scoped=true&"
import script from "./bedManage.vue?vue&type=script&lang=js&"
export * from "./bedManage.vue?vue&type=script&lang=js&"
import style0 from "./bedManage.vue?vue&type=style&index=0&id=40e6df66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e6df66",
  null
  
)

export default component.exports